import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { SingleCategoryDocumentsContext } from "../contexts/SingleCategoryDocumentsContext";
import { storage } from "../firebase";

import Pagination from "./SingleCategoryPage/Pagination";
import Posts from "./SingleCategoryPage/Posts";
import ProgressBar from "./SingleCategoryPage/ProgressBar";
import { useTitle } from "./useTitle";
// import { ErrorContext } from "../contexts/ErrorContext";
import { scrollToTop } from "../Helpers/ScrollToTop";
import CategoryPageHeader from "./SingleCategoryPage/CategoryPageHeader";
import Footer from "./Footer/Footer";
// import Advert from "./Advert";
// const CategoryPageHeader = React.lazy(() => import("./SingleCategoryPage/CategoryPageHeader"));
// const Advert = React.lazy(() => import("./Advert"));
// const Footer = React.lazy(() => import("./Footer/Footer"));

const useStyles = makeStyles((theme) => ({
  mainCard: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0,
      marginTop: 200,
    },
    marginRight: 12,
    marginLeft: 48,
    marginTop: 100,
    backgroundColor: "#fff8ea",
  },
  advert: {
    marginTop: 150,
    [theme.breakpoints.up("lg")]: {
      marginTop: 100,
    },
  },
}));

function SingleCategoryPage() {
  const classes = useStyles();
  const { categoryName } = useParams();
  const history = useHistory();
  useTitle(`${categoryName} - Free Legal Documents, Advice, Contracts & Forms.`);

  const [progress, setProgress] = useState(40);
  const [backdropOpen, setBackdropOpen] = useState(false);
  
  const [error, setError] = useState("");

  // Pagination Stuff
  // const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);

  // Change page
  const paginate = (pageNumber) => {
    showBackdropFor1Second();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // Getting the documents of single category
  const [singleCategoryDocuments, setSingleCategoryDocuments] = useContext(
    SingleCategoryDocumentsContext
  );

  // Backdrop logic
  async function showBackdropFor1Second(){
    setBackdropOpen(true);
    setTimeout(() => {
      setBackdropOpen(false);
      console.log("timeout");
    }, 1000);
  }

  async function listCategoryItems() {
    let listUrl = `Documents/${categoryName}`;
    const listRef = storage.ref(listUrl);
    // Downloading from firebase
    listRef
      .listAll()
      .then((res) => {
        const itemArray = [{}];
        res.items.forEach((folderRef) => {
          const fullPath = folderRef.fullPath;
          const name = folderRef.name;
          itemArray.push({ fullPath, name });
        });
        setProgress((progress) => progress + 20);
        setSingleCategoryDocuments(itemArray);
        setProgress((progress) => progress + 30);
      })
      .catch((e) => {
        setError("Something Went Wrong");
        history.push("/error");
      });
    setProgress((progress) => progress + 10);
  }

  useEffect(() => {
    setProgress(80);
    listCategoryItems();
    scrollToTop();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = singleCategoryDocuments.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <div style={{ position: "fixed", width: "100vw", zIndex: 1 }}>
          <CategoryPageHeader />
      </div>
      {/* Progressbar */}
      <div style={{ position: "fixed", width: "100vw" }}>
        <ProgressBar progress={progress} />
      </div>

      <Grid container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor:"#fff"}}
      >
        <Grid item xs={12} md={10}>
          <Card className={classes.mainCard}>
            <CardHeader
              title={
                <Typography component="h1" variant="h4">
                  {`Showing all items in "${categoryName ? categoryName : null}"`}
                </Typography>
              }
            />
            <CardContent>
              <Posts posts={currentPosts} loading={loading} />

              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={singleCategoryDocuments.length}
                paginate={paginate}
                showBackdropFor1Second={showBackdropFor1Second}
              />
            </CardContent>
          </Card>
        </Grid>
        {/* Right grid, for advert */}
        {/* <Grid item xs={12} md={3} className={classes.advert}>
          <Advert />
          <div style={{ marginTop: 48 }}>&nbsp;</div>
          <Advert />
        </Grid> */}
      </Grid>

      <div style={{ paddingTop: 100, backgroundColor: "#fff" }}>&nbsp;</div>

  
      <Footer />

      {/* Backdrop to show when clicked on a page */}
      <Backdrop
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default SingleCategoryPage;
