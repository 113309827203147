import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import React from "react";
import { useHistory } from "react-router";
import {
  HomeIcon,
  FamilyIcon,
  PersonIcon,
  NoticeIcon,
  BulbIcon,
  FinanceIcon,
  SuitIcon,
  BusinessIcon,
  DraftingIcon,
  AgreementIcon,
  ApplicationIcon,
  DeedsIcon,
  AffidavitsIcon,
  OthersIcon,
  HindiIcon,
} from "./icons";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    marginLeft: 40,
    marginTop: 40,
    height: 200,
    width: 300,
    // backgroundColor: "#2c2f33",
    backgroundColor: "#fff8ea",
    color: "#000",
    borderRadius: 12,
    boxShadow: "2px 2px 5px #d9d3c7, -2px -2px 5px #ffffff;",
    "&:hover": {
      // boxShadow: "4px 4px 20px 10px #3f3f3f",
      cursor:"pointer",
      transition: "transform 200ms ease-in-out",
      transform: "scale(1.1)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: 0,
      marginTop: 16,
      // marginBottom: 8,
      height: 80,
      width: 350,
    },
  },
  iconPC: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconMobile: {
    marginLeft: 12,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  headerPC: {
    marginBottom: 0,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contentPC: {
    margin: 0,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentMobile: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 8,
    marginRight: 0,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contentButtonPC: {
    margin: 0,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentButtonMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function SingleCategoryCard({ document }) {
  const classes = useStyles();
  const history = useHistory();

  const cardNames = [
    "Real Estate",
    "Notices",
    "Family",
    "Personal",
    "IPR",
    "Financial",
    "Suits",
    "Business",
    "Drafting",
    "Agreements",
    "Applications",
    "Deeds",
    "Affidavits",
    "Hindi Documents",
  ];

  return (
    <>
      <Card
        component="a"
        className={classes.mainCard}
        onClick={() => {
          // window.location.href = `/Category/${document.name}`;
          history.push(`/Category/${document.name}`);
        }}
      >
        <CardHeader
          className={classes.headerPC}
          title={
            <Typography style={{ textAlign: "center", fontSize: 24 }}>{document.name}</Typography>
          }
        />
        {cardNames.includes(document.name) ? (
          <CardContent className={classes.contentPC}>
            {document.name === "Real Estate" ? <HomeIcon /> : null}
            {document.name === "Notices" ? <NoticeIcon /> : null}
            {document.name === "Family" ? <FamilyIcon /> : null}
            {document.name === "Personal" ? <PersonIcon /> : null}
            {document.name === "IPR" ? <BulbIcon /> : null}
            {document.name === "Financial" ? <FinanceIcon /> : null}
            {document.name === "Suits" ? <SuitIcon /> : null}
            {document.name === "Business" ? <BusinessIcon /> : null}
            {document.name === "Drafting" ? <DraftingIcon /> : null}
            {document.name === "Agreements" ? <AgreementIcon /> : null}
            {document.name === "Applications" ? <ApplicationIcon /> : null}
            {document.name === "Deeds" ? <DeedsIcon /> : null}
            {document.name === "Affidavits" ? <AffidavitsIcon /> : null}
            {document.name === "Hindi Documents" ? <HindiIcon /> : null}
          </CardContent>
        ) : (
          <CardContent className={classes.contentPC}>
            <OthersIcon />
          </CardContent>
        )}
        {/* For Mobile */}
        {cardNames.includes(document.name) ? (
          <CardContent className={classes.contentMobile}>
            {document.name === "Real Estate" ? <HomeIcon /> : null}
            {document.name === "Notices" ? <NoticeIcon /> : null}
            {document.name === "Family" ? <FamilyIcon /> : null}
            {document.name === "Personal" ? <PersonIcon /> : null}
            {document.name === "IPR" ? <BulbIcon /> : null}
            {document.name === "Financial" ? <FinanceIcon /> : null}
            {document.name === "Suits" ? <SuitIcon /> : null}
            {document.name === "Business" ? <BusinessIcon /> : null}
            {document.name === "Drafting" ? <DraftingIcon /> : null}
            {document.name === "Agreements" ? <AgreementIcon /> : null}
            {document.name === "Applications" ? <ApplicationIcon /> : null}
            {document.name === "Deeds" ? <DeedsIcon /> : null}
            {document.name === "Affidavits" ? <AffidavitsIcon /> : null}
            {document.name === "Hindi Documents" ? <HindiIcon /> : null}
          </CardContent>
        ) : (
          <CardContent className={classes.contentMobile}>
            <OthersIcon />
          </CardContent>
        )}
        <CardContent target="_blank" className={classes.contentButtonPC}>
          <Button
            style={{
              paddingLeft: 58,
              paddingRight: 58,
              marginTop: 6,
              marginLeft: 8,
              marginRight: 8,
              textTransform: "none",
              fontSize: 16,
              borderRadius: 8,
            }}
            target="_blank"
            // rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              // const win = window.open(`/Category/${document.name}`, "_blank");
              // win.focus();
              history.push(`/Category/${document.name}`);
            }}
            // href={"/Category/" + document.name}
          >
            View Documents
          </Button>
        </CardContent>

        {/* Mobile Card Names */}
        <CardHeader
          className={classes.headerMobile}
          title={
            <Typography style={{ textAlign: "center", fontSize: 24 }}>{document.name}</Typography>
          }
        />

        {/* For Mobile */}
        <CardContent target="_blank" className={classes.contentButtonMobile}>
          <IconButton
            style={{
              textTransform: "none",
              fontSize: 16,
              borderRadius: 8,
            }}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/Category/${document.name}`);
            }}
            // href={"/Category/" + document.name}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </CardContent>
      </Card>
    </>
  );
}
