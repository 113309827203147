import { Button, Grid, makeStyles } from "@material-ui/core";
import Logo from "../../assets/images/Free Legal Documents, Advice, Contracts & Forms - Logo.png";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";

import { TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { SingleCategoryDocumentsContext } from "../../contexts/SingleCategoryDocumentsContext";
import { SearchResultsContext } from "../../contexts/SearchResultsContext";
// import { ErrorContext } from "../../contexts/ErrorContext";
import { useHistory } from "react-router";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { useParams } from "react-router-dom";
import { storage } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchBar: {
    [theme.breakpoints.down("md")]: {
      fullWidth: false,
    },
  },
  searchBtn: {
    height: 56,
    backgroundColor: "#137008",
    "&:hover": {
      backgroundColor: "#13C008",
    },
  },
  backBtn: {
    height: 56,
    backgroundColor: "#FF7033",
    "&:hover": {
      backgroundColor: "#FFC033",
    },
  },
  homeBtn: {
    height: 56,
    backgroundColor: "#FF7033",
    "&:hover": {
      backgroundColor: "#FFC033",
    },
  },
}));

function CategoryPageHeader() {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [singleCategoryDocuments, setSingleCategoryDocuments] = useContext(
    SingleCategoryDocumentsContext
  );
  const [searchResults, setSearchResults] = useContext(SearchResultsContext);

  const history = useHistory();
  const { categoryName } = useParams();

  function search(e) {
    e.preventDefault();
    // setSearchResults([]);
    const searchTerms = searchText.split(" ");
    const searchResultsArray = [];

    // IF SEARCHED FROM ALL
    if (window.location.href.includes("All")) {
      let listUrl = `Documents/All`;
      const listRef = storage.ref(listUrl);
      listRef
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            const fullPath = itemRef.fullPath;
            const name = itemRef.name;
            if (name.toLowerCase().includes(searchText.toLowerCase())) {
              searchResultsArray.push({
                fullPath,
                name,
              });
            }
          });
          setSearchResults(searchResultsArray);
        })
        .catch(() => {
          history.push("/error");
        });
      history.push(`/Category/${categoryName}/Search/${searchText}`);
      setSearchText("");
      return;
    }

    // Searching the Whole Category State for search keywords
    singleCategoryDocuments.forEach((singleCategoryDocument) => {
      const fullPath = singleCategoryDocument.fullPath;
      const name = singleCategoryDocument.name;
      // checking for each items in search textfield and Pushing to array if term matches
      if (name && searchTerms.every((term) => name.toLowerCase().includes(term.toLowerCase()))) {
        searchResultsArray.push({ fullPath, name });
      }
    });
    console.log("searchResultsArray", searchResultsArray);
    setSearchResults(searchResultsArray);

    // Go to Search Page
    history.push(`/Category/${categoryName}/Search/${searchText}`);
    setSearchText("");
  }

  return (
    <>
      <div
        style={{
          marginBottom: 96,
          padding: 8,
          // backgroundColor: "#2c2f33",
          backgroundColor: "#f2faff",
          position: "sticky",
          top: 0,
          // position: "-webkit-sticky",
        }}
      >
        <Grid
          container
          spacing={1}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={1}>
            <Button
              fullWidth
              className={classes.homeBtn}
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
            >
              <HomeRoundedIcon />
            </Button>
          </Grid>

          <Grid item xs={6} md={1}>
            <Button
              fullWidth
              className={classes.backBtn}
              disabled={window.location.href.includes("Search") ? false : true}
              onClick={() => {
                if (categoryName === "All" || categoryName === "" || !categoryName) {
                  history.push("/");
                } else {
                  history.push(`/Category/${categoryName}`);
                }
              }}
            >
              <ArrowBackRoundedIcon />
            </Button>
          </Grid>

          <Grid item xs={10} md={8}>
            <TextField
              id="search-documents"
              fullWidth
              className={classes.searchBar}
              label="Search Documents"
              variant="outlined"
              onChange={(e) => setSearchText(e.target.value)}
              inputProps={{
                maxLength: 72,
              }}
              onKeyDown={(e) => {
                if (searchText !== "") {
                  if (e.key === "Enter") {
                    search(e);
                  }
                }
              }}
              // style={{ backgroundColor: "#2c2f33" }}
            />
          </Grid>

          <Grid item xs={2} md={1}>
            <Button
              fullWidth
              disabled={searchText === "" ? true : false}
              className={classes.searchBtn}
              onClick={(e) => {
                search(e);
              }}
            >
              <SearchRoundedIcon />
            </Button>
          </Grid>

          <Grid item xs={false} md={1} className={classes.logo}>
            <img
              src={Logo}
              alt="waqalat logo"
              style={{ height: 56, width: 56 }}
              onClick={() => {
                history.push("/");
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default React.memo(CategoryPageHeader);
