import { useEffect } from "react";

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;
    // if (!document.title.toLowerCase().includes("waqalat"))
    document.title = `${title} - Waqalat.in`;
    return () => {
      document.title = prevTitle;
    };
  }, []);
}
