import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { SearchResultsContext } from "../contexts/SearchResultsContext";

import ProgressBar from "./SingleCategoryPage/ProgressBar";
import { scrollToTop } from "../Helpers/ScrollToTop";
import { useTitle } from "./useTitle";
import download from "../Helpers/download";
import share from "../Helpers/share";
const CategoryPageHeader = React.lazy(() => import("./SingleCategoryPage/CategoryPageHeader"));
const RecaptchaComponent = React.lazy(() => import("./RecaptchaComponent"));
const Advert = React.lazy(() => import("./Advert"));
const Footer = React.lazy(() => import("./Footer/Footer"));

const useStyles = makeStyles((theme) => ({
  mainCard: {
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginLeft: 0,
      marginTop: 150,
    },
    marginRight: 12,
    marginLeft: 48,
    marginTop: 100,
    backgroundColor: "#fff8ea",
  },
  advert: {
    marginTop: 150,
    [theme.breakpoints.up("lg")]: {
      marginTop: 100,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function SearchResultsPage() {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [searchResults] = useContext(SearchResultsContext);
  const { searchTerms } = useParams();
  useTitle(`${searchTerms} - Free Legal Documents, Advice, Contracts & Forms.`);

  // Captcha Stuff
  const [open, setOpen] = React.useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const handleClose = () => {
    setDownloadDisabled(true);
    setOpen(false);
  };

  const [downloadDisabled, setDownloadDisabled] = useState(true);

  useEffect(() => {
    scrollToTop();
    setProgress(100);
  }, []);

  return (
    <>
      <div style={{ position: "fixed", width: "100vw", zIndex: 1 }}>
        <Suspense fallback={<div>Loading...</div>}>
          <CategoryPageHeader />
        </Suspense>
      </div>
      {/* Progressbar */}
      <div style={{ position: "fixed", width: "100vw" }}>
        <ProgressBar progress={progress} />
      </div>

      <Grid container       
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor:"#fff"}}
      >
        <Grid item xs={12} md={10}>
          <Card className={classes.mainCard}>
            <CardHeader
              title={
                // searchResults.length>1? 
                  <Typography component="h1" variant="h4">
                    Showing results for {searchTerms ? searchTerms : null}&nbsp;
                    {searchResults.length > 1 ? `${searchResults.length} Items` : ""}
                  </Typography>
                  // :
                  // <Typography component="h1" variant="h4">
                  //   Showing results for {searchTerms ? searchTerms : null}&nbsp;<br/>
                  //   We Could't Find The Document You Were Looking For. Maybe Our Discord Community Can Help?
                  // </Typography>
              }
            />
            <CardContent>
              <List>
                {searchResults &&
                  searchResults.map(
                    (singleSearchResult) =>
                      singleSearchResult.name && singleSearchResult.name.length >= 1 ? (
                        <ListItem
                          key={singleSearchResult.fullPath}
                          button
                          component="a"
                          onClick={(e) => {
                            setOpen(true);
                            setDownloadLink(singleSearchResult.fullPath);
                          }}
                          style={{
                            border: "1px solid #777",
                            borderRadius: 5,
                            marginTop: 12,
                          }}
                        >
                          <ListItemText
                            primary={singleSearchResult.name.substring(
                              0,
                              singleSearchResult.name.lastIndexOf(".")
                            )}
                          />
                          <ListItemIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-file-earmark-arrow-down-fill"
                              viewBox="0 0 16 16"
                              style={{ marginRight: 40 }}
                            >
                              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                            </svg>
                          </ListItemIcon>
                          <IconButton onClick={(e) => share(e, singleSearchResult)}>
                            <ShareIcon />
                          </IconButton>
                        </ListItem>
                      ) : (
                        <CircularProgress style={{ color: "#006098" }} size={50} />
                      )
                    // </Link>
                  )}
              </List>
              {searchResults.length==0 && searchResults[0]===undefined ?<Typography component="h2" variant="h5">We Could't Find The Document You Were Looking For, Maybe Our <a href="https://discord.gg/Xffwkh3emk" target="_blank" style={{textDecoration:"none",textTransform:"none"}}>Discord Community</a> Can Help ?</Typography>:null}
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={3} className={classes.advert}>
          <Suspense fallback={<div>Loading...</div>}>
            <Advert />
          </Suspense>

          <div style={{ marginTop: 48 }}>&nbsp;</div>
        </Grid> */}
      </Grid>
      <div style={{ paddingTop: 100, backgroundColor:"#fff" }}>&nbsp;</div>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>

      {/* Captcha */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Fetching...</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Wait 5 Seconds</DialogContentText>
        </DialogContent>
        <DialogContent style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Suspense fallback={<div>...</div>}>
            <RecaptchaComponent setDownloadDisabled={setDownloadDisabled} />
          </Suspense>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <a href={downloadLink} download={downloadName} style={{ textDecoration: "none" }}> */}
          <Button
            disabled={downloadDisabled}
            id="submitBtn"
            onClick={(e) => {
              download(e, downloadLink);
              handleClose();
            }}
            autoFocus
          >
            Download
          </Button>
          {/* </a> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
