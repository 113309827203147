import React, {useState} from "react";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";


import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import {DiscordIcon} from "../Main/icons";

import Orioness from "../../assets/images/footer/Orioness.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    // marginTop: 72,
    paddingTop: 12,
    paddingBottom: 24,
    // background: "linear-gradient(-45deg, #137008, #13C008, #FFC033, #FF7033)",
    // backgroundSize: "400% 400%",
    // animation: "$gradient 15s ease infinite",
    backgroundColor: "#F3FCF0"
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  statsImg: {
    display: "inline-block",
    height: 170,
    width: 170,
  },
  socialBtn: {
    height: 60,
    width: 60,
  },
  iconBtn: {
    marginLeft: 4,
    marginRight: 4,
    "&:hover": {
      background: "none",
    },
  },
  ytBtn: {
    "&:hover": {
      color: "#c00",
    },
  },
  fbBtn: {
    "&:hover": {
      color: "#1877F2",
    },
  },
  instaBtn: {
    "&:hover": {
      color: "#bc2a8d",
    },
  },
  twitterBtn: {
    "&:hover": {
      color: "#1DA1F2",
    },
  },
  telegramBtn: {
    "&:hover": {
      color: "#0088cc",
    },
  },
  linkedInBtn: {
    "&:hover": {
      color: "#0077b5",
    },
  },
  discordBtn: {
    "&:hover": {
      color: "#5865F2",
    },
  },
  parentImg: {
    [theme.breakpoints.up("sm")]: {
      width: 311,
      height: 66,
    },
    height: 66,
    width: 311,
    marginTop: 0,
    marginBottom: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  function openLink(e, link) {
    e.preventDefault();
    window.open(link);
  }

  const [dHover, setDHover] = useState(false);

  return (
    <>
      {/* Left Stats Images */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.footer}
      >
        {/* Left Stuff - links */}
        <Grid item xs={12} md={6}>
          <Grid container direction="column" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <Typography style={{ fontSize: 28, fontWeight: 600, paddingTop: 16 }}>
                Follow Us On :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                className={`${classes.iconBtn} ${classes.ytBtn}`}
                onClick={(e) =>
                  openLink(e, "https://www.youtube.com/channel/UC8zcnmWnEvECzfqO2JjQq0A")
                }
              >
                <YouTubeIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.fbBtn}`}
                onClick={(e) => openLink(e, "https://www.facebook.com/groups/waqalat.in")}
              >
                <FacebookIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.instaBtn}`}
                onClick={(e) => openLink(e, "https://www.instagram.com/waqalat.in/")}
              >
                <InstagramIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.twitterBtn}`}
                onClick={(e) => openLink(e, "https://twitter.com/Waqalat_in")}
              >
                <TwitterIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.telegramBtn}`}
                onClick={(e) => openLink(e, "https://t.me/waqalat")}
              >
                <TelegramIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.linkedInBtn}`}
                onClick={(e) => openLink(e, "https://www.linkedin.com/company/waqalat-in")}
              >
                <LinkedInIcon className={classes.socialBtn} />
              </IconButton>
              <IconButton
                className={`${classes.iconBtn} ${classes.discordBtn}`}
                onClick={(e) => openLink(e, "https://discord.gg/Xffwkh3emk")}
                onMouseOver={()=>setDHover(true)}
                onMouseOut={()=>setDHover(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" fill={dHover?"#5865F2":"#777"} viewBox="0 0 16 16">
                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                </svg>
                {/* <DiscordIcon className={classes.socialBtn} /> */}
              </IconButton>
              
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: 28, fontWeight: 600, paddingTop: 16 }}>
                Waqalat.in is a subsidiary of
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img 
                src={Orioness} alt="Waqalat Parent Company" 
                className={classes.parentImg} 
                onClick={(e)=>{
                  window.open("https://www.orioness.in", '_blank');
                }} />
            </Grid>
          {/* <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <img
              src={Users}
              alt="Free Legal Documents, Advice, Contracts & Forms Users"
              className={classes.statsImg}
            />
            <img
              src={downs}
              alt="Free Legal Documents, Advice, Contracts & Forms Downs"
              className={classes.statsImg}
            />
            <img
              src={Fee}
              alt="Free Legal Documents, Advice, Contracts & Forms Fee"
              className={classes.statsImg}
            />
          </Grid> */}
        </Grid>

        </Grid>
    </>
  );
}
