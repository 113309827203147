import { Button, Link, List, makeStyles } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  paginate: {
    [theme.breakpoints.up("lg")]: {
      // marginLeft: 36,
    },
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Pagination({ postsPerPage, totalPosts, paginate }) {
  const classes = useStyles();

  const url = window.location.href;
  let urlNumber = url.slice(url.length - 2);
  if (urlNumber.includes("#")) {
    urlNumber = urlNumber.slice(1);
  }

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div style={{}}>
      <List dense className={`${classes.paginate} pagination`}>
        {pageNumbers.map((number) => (
          <Button
            key={number}
            onClick={() => {
              paginate(number);
            }}
            href={`#${number}`}
            className="page-item"
            style={{
              textTransform: "none",
              // padding: 6,
              margin: 4,
              border: "1px solid #000",
              borderRadius: 4,
              maxWidth: "40px",
              maxHeight: "40px",
              minWidth: "40px",
              minHeight: "40px",
              borderRadius: 20,
              backgroundColor: number == urlNumber ? "Highlight" : "",
            }}
          >
            {number}
          </Button>
        ))}
      </List>
    </div>
  );
}
