import { storage } from "../firebase";

export default async function share(e, post) {
  e.preventDefault();
  e.stopPropagation();
  let storageRef = storage.ref(),
    shareUrl = "";
  let starsRef = storageRef.child(`${post.fullPath}`);
  await starsRef.getDownloadURL().then((url) => {
    shareUrl = url;
  });
  const shareData = {
    title: `${post.name}`,
    text: `${post.name}`,
    url: `${shareUrl}`,
  };
  await navigator.share(shareData);
}
