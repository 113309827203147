import "./App.css";

import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme, CssBaseline, Grid } from "@material-ui/core";

import MainPage from "./components/MainPage";
import SingleCategoryPage from "./components/SingleCategoryPage";
import { SingleCategoryDocumentsProvider } from "./contexts/SingleCategoryDocumentsContext";
import SearchResultsPage from "./components/SearchResultsPage";
import { SearchResultsProvider } from "./contexts/SearchResultsContext";
import ErrorPage from "./components/ErrorPage";
// import { ErrorProvider } from "./contexts/ErrorContext";

function App() {
  return (
    // <ErrorProvider>
    <SingleCategoryDocumentsProvider>
      <SearchResultsProvider>
        <ThemeProvider
          theme={createTheme({
            palette: {
              type: "light",
              primary: {
                main: "#2196F3",
              },
            },
            typography: {
              fontFamily: '"Poppins", cursive',
            },
            overrides: {
              MuiCssBaseline: {
                "@global": {
                  "@font-face": [],
                },
              },
            },
          })}
        >
          <CssBaseline />
          <div className="App">
            {/* <Grid container spacing={0}> */}
            <BrowserRouter>
              <Grid item xs={12}>
                <Route path="/" exact component={MainPage} />
              </Grid>
              <Grid item xs={12}>
                <Route path={`/Category/:categoryName`} exact component={SingleCategoryPage} />
              </Grid>
              <Grid item xs={12}>
                <Route path={`/Category/:categoryName/Search/:searchTerms`} exact component={SearchResultsPage} />
              </Grid>
              <Grid item xs={12}>
                <Route path={`/Search/:searchTerms`} exact component={SearchResultsPage} />
              </Grid>
              <Grid item xs={12}>
                <Route path={`/error`} exact component={ErrorPage} />
              </Grid>
            </BrowserRouter>
            {/* </Grid> */}
          </div>
        </ThemeProvider>
      </SearchResultsProvider>
    </SingleCategoryDocumentsProvider>
    // </ErrorProvider>
  );
}

export default App;
