import React, { createContext, useState } from "react";

export const SingleCategoryDocumentsContext = createContext();

export const SingleCategoryDocumentsProvider = (props) => {
  const [singleCategoryDocuments, setSingleCategoryDocuments] = useState([
    {
      fullPath: null,
      name: null,
    },
  ]);

  return (
    <SingleCategoryDocumentsContext.Provider
      value={[singleCategoryDocuments, setSingleCategoryDocuments]}
    >
      {props.children}
    </SingleCategoryDocumentsContext.Provider>
  );
};
