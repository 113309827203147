import { LinearProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  progressBar: {
    marginTop: 76,
    [theme.breakpoints.down("sm")]: {
      marginTop: 136,
    },
  },
}));

export default function ProgressBar({ progress }) {
  const classes = useStyles();

  return (
    <>
      <div style={{}} className={classes.progressBar}>
        {progress >= 100 ? null : <LinearProgress variant="determinate" value={progress} />}
      </div>
    </>
  );
}
