import { storage } from "../firebase";

export default function download(e, fullPath, history) {
  e.preventDefault();
  console.log("history", history);
  let storageRef = storage.ref();
  let starsRef = storageRef.child(`${fullPath}`);
  starsRef
    .getDownloadURL()
    .then((url) => {
      window.location.assign(url);
    })
    .catch((error) => {
      switch (error.code) {
        case "storage/object-not-found":
          // setError("File Not Found");https://www.waqalat.in
          // window.location.replace("/error");
          history.push("/error");
          break;
        case "storage/unauthorized":
          // setError("User is UnAuthorized");
          // window.location.replace("/error");
          history.push("/error");
          break;

        case "storage/unknown":
          // setError("Unknown Error Occurred");
          // window.location.replace("/error");
          history.push("/error");
          break;
      }
    });
}
