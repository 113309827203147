import React, { createContext, useState } from "react";

export const SearchResultsContext = createContext();

export const SearchResultsProvider = (props) => {
  const [searchResults, setSearchResults] = useState([
    {
      fullPath: null,
      name: null,
    },
  ]);

  return (
    <SearchResultsContext.Provider value={[searchResults, setSearchResults]}>
      {props.children}
    </SearchResultsContext.Provider>
  );
};
