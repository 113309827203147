import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import React, { useState } from "react";
import { useHistory } from "react-router";
import download from "../../Helpers/download";
import share from "../../Helpers/share";
import RecaptchaComponent from "../RecaptchaComponent";
// const RecaptchaComponent = React.lazy(() => import("../RecaptchaComponent"));

export default function Posts({ posts }) {
  const [open, setOpen] = React.useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    setDownloadDisabled(true);
  };

  const [downloadDisabled, setDownloadDisabled] = useState(true);

  return (
    <>
      <List>
        {posts.map((post) =>
          post.name ? (
            <ListItem
              key={post.name}
              button
              component="a"
              onClick={(e) => {
                setOpen(true);
                setDownloadLink(post.fullPath);
              }}
              style={{
                border: "1px solid #777",
                borderRadius: 5,
                marginTop: 12,
              }}
            >
              <ListItemText primary={post.name.substring(0, post.name.lastIndexOf("."))} />
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-file-earmark-arrow-down-fill"
                  viewBox="0 0 16 16"
                  style={{ marginRight: 40 }}
                >
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                </svg>
              </ListItemIcon>
              <IconButton onClick={(e) => share(e, post)}>
                <ShareIcon />
              </IconButton>
            </ListItem>
          ) : null
        )}
      </List>

      {/* Captcha */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ marginBottom: 0 }} id="alert-dialog-title">
          Fetching...
        </DialogTitle>
        <DialogContent style={{ marginTop: 0, marginBottom: 0 }}>
          <DialogContentText>Please Wait 5 Seconds</DialogContentText>
        </DialogContent>
        <DialogContent style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <RecaptchaComponent setDownloadDisabled={setDownloadDisabled} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <a href={downloadLink} download={downloadName} style={{ textDecoration: "none" }}> */}
          <Button
            disabled={downloadDisabled}
            id="submitBtn"
            onClick={(e) => {
              download(e, downloadLink, history);
              handleClose();
            }}
            autoFocus
          >
            Download
          </Button>
          {/* </a> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
