import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ErrorImg from "../assets/images/Free Legal Documents, Advice, Contracts & Forms - Error.png";
import { useTitle } from "./useTitle";

const useStyles = makeStyles((theme) => ({
  errorImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 96,
    height: 200,
    width: 300,
    [theme.breakpoints.up("sm")]: {
      height: 400,
      width: 600,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 24,
      height: 600,
      width: 900,
    },
  },
  backBtn: {
    backgroundColor: "#006098",
    marginTop: 96,
    [theme.breakpoints.up("sm")]: {
      marginTop: 48,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 24,
    },
  },
}));

export default function ErrorPage() {
  const classes = useStyles();
  const history = useHistory();

  useTitle("Error - Free Legal Documents, Affidavits, Agreements etc.");

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <img
          className={classes.errorImg}
          src={ErrorImg}
          height={600}
          width={800}
          alt="Free Legal Documents, Advice, Contracts & Forms - Error"
        />
        <Button
          disableElevation
          size="large"
          className={classes.backBtn}
          onClick={() => {
            history.push("/");
          }}
        >
          Go Back
        </Button>
      </Grid>
    </>
  );
}
