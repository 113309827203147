import React, { useEffect, useState } from "react";
import { Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";

import { useTitle } from "./useTitle";
import { useHistory } from "react-router";
// import { ErrorContext } from "../contexts/ErrorContext";
import { scrollToTop } from "../Helpers/ScrollToTop";
import { storage } from "../firebase";
// import InstallPWA from "./Main/InstallPWA";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import SingleCategoryCard from "./Main/SingleCategoryCard";
// const Header = React.lazy(() => import("./Header/Header"));
// const Footer = React.lazy(() => import("./Footer/Footer"));
// const SingleCategoryCard = React.lazy(() => import("./Main/SingleCategoryCard"));
// const Advert = React.lazy(() => import("./Advert"));

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "sans-serif",
    textAlign: "center",
    height: "100%",
    backgroundColor: "#181a1b",
    // backgroundColor: "#FFEDCB",
  },
  logoName: {
    marginTop: 24,
    marginBottom: 24,
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
    backgroundColor: "#f6f6f6",
  },
  particlesContainer: {
    position: "absolute",
  },
  cardArea: {
    // marginLeft: 0,
    [theme.breakpoints.up("lg")]: {
      // marginLeft: 24,
      // marginRight: 24,
    },
  },
  footer: {
    // backgroundColor: "#181a1b",
    marginTop: 56,
    minHeight: 300,
    background: "linear-gradient(-45deg, #FF7033, #FFC033, #13C008, #137008)",
    backgroundSize: "400% 400%",
    animation: "$gradient 10s ease infinite",
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  sendDocuments: {
    marginLeft: 40,
    marginTop: 36,
    borderRadius: 12,
    // paddingLeft: 120,
    // paddingRight: 120,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#2c2f33",
    backgroundColor: "#fff8ea",
    [theme.breakpoints.up("md")]: {
      width: 1050,
    },
    [theme.breakpoints.up("lg")]: {
      width: 1150,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  widgets: {
    // marginLeft: "auto",
    // marginRight: "auto",
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: 36,
    // },
  },
}));

export default function MainPage() {
  const classes = useStyles();
  const history = useHistory();
  useTitle("Free Legal Documents, Advice, Contracts & Forms.");

  const [documents, setDocuments] = useState([
    {
      bucket: null,
      fullPath: null,
      name: null,
    },
  ]);
  // const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  function listDocuments() {
    let listRef = storage.ref("Documents");
    listRef
      .listAll()
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          const bucket = folderRef.bucket;
          const fullpath = folderRef.fullPath;
          const name = folderRef.name;
          setDocuments((documents) => [
            ...documents,
            {
              bucket,
              fullpath,
              name,
            },
          ]);
        });
      })
      .catch((error) => {
        console.log(error);
        history.push("/");
      });
  }

  useEffect(() => {
    listDocuments();
    scrollToTop();
  }, []);

  return (
    <>
      <Header documents={documents} />

      {/* Middle Area */}
      <Grid
        container
        spacing={0}
        // direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#FFEDCB",
          backgroundColor: "#fff",
          paddingBottom: 72,
        }}
      >
        {/* GO TO MAIN SITE */}
        <Paper className={classes.sendDocuments}>
          <Typography align="center" style={{ margin: 0, fontSize: 24 }}>
            <Link href={`https://www.waqalat.in`} style={{ borderBottom: "none", textDecoration:"none" }}>
              Return To Main Site
            </Link>
          </Typography>
        </Paper>
        {/* Cards Area */}
        <Grid item xs={12} md={10}>
          <Grid container className={classes.cardArea}>
            {documents.map((document) =>
              document.name && document.name != "All" ? (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={document.name}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                    <SingleCategoryCard document={document} />
                </Grid>
              ) : null
            )}
          </Grid>
          <Grid container className={classes.cardArea}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper className={classes.sendDocuments}>
                {/* <CardContent> */}
                <Typography align="center" style={{ margin: 0, fontSize: 24 }}>
                  Feel free to send additional documents to{" "}
                  <Link href={`mailto:waqalat.in@gmail.com`} style={{ borderBottom: "none" }}>
                    waqalat.in@gmail.com
                  </Link>
                </Typography>
                {/* <Typography align="center" style={{ margin: 8, fontSize: 24 }}>
                  For any questions you can visit our&nbsp;
                  <Link href={`https://waqalat-in.tawk.help/`} style={{ borderBottom: "none" }}>
                    Help Center
                  </Link>
                  &nbsp;or&nbsp;
                  <Link
                    href={`https://tawk.to/chat/6143146cd326717cb681c2c8/1ffn0fi9f`}
                    style={{ borderBottom: "none" }}
                  >
                    Chat With Us
                  </Link>
                </Typography> */}

                {/* </CardContent> */}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.widgets}
          >
            <Typography
              style={{
                width: 300,
                marginTop: 36,
                color: "#7289da",
              }}
            >
              Use The "Connect" Button Below For Free Legal Advice & Discussions
            </Typography>
            <iframe
              style={{
                marginTop: 12,
                marginBottom: 24,
              }}
              src="https://discord.com/widget?id=883660698020896778&theme=dark"
              width="300"
              height="195"
              allowtransparency="true"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
            <Suspense fallback={<div>Loading...</div>}>
              <Advert />
            </Suspense>
          </Grid>
        </Grid> */}
      </Grid>

        <Footer />
      {/* <Box
        sx={{ width: "100%" }}
        style={{
          display: "inline-block",
          position: "fixed",
          zIndex: 2,
          backgroundColor: "#fafafa",
          width: "100vw",
        }}
      > */}
      {/* <div style={{ position: "fixed", width: "100vw", zIndex: 1 }}>
        <InstallPWA />
      </div> */}
      {/* </Box> */}
    </>
  );
}
