// import Recaptcha from "react-recaptcha";

// const production = false;

// export default function RecaptchaComponent({ setDownloadDisabled }) {
//   // const [disabled, setDisabled] = useState(true);

//   function recaptchaCallback() {
//     setDownloadDisabled(false);
//     // document.getElementById("#submitBtn").removeAttr("disabled");
//     // setDisabled(false);
//   }

//   return (
//     <>
//       <Recaptcha
//         sitekey={
//           production
//             ? "6LcyYHIcAAAAAAknObVEVdn5yqSwZpDOkoBCROCO"
//             : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
//         }
//         // render="explicit"
//         verifyCallback={recaptchaCallback}
//       />
//     </>
//   );
// }

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";

export default function RecaptchaComponent({ setDownloadDisabled }) {
  const [circularProgressValue, setCircularProgressValue] = useState(0);

  useEffect(() => {
    setDownloadDisabled(true);
    const timeout = setTimeout(() => {
      setDownloadDisabled(false);
    }, 5300);
    const timer = setInterval(() => {
      setCircularProgressValue((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 2));
    }, 100);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Box sx={{ position: "relative", display: "inline-flex", marginBottom: 12 }}>
        <CircularProgress
          style={{ color: "#006098" }}
          variant="determinate"
          size={70}
          value={circularProgressValue}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" style={{ fontSize: 16 }}>
            {`${(Math.round(circularProgressValue / 20) - 5) * -1}`}
          </Typography>
        </Box>
      </Box>
      {/* <CircularProgress variant="determinate" size={70} value={circularProgressValue} /> */}
    </>
  );
}
