import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Suspense, useState } from "react";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import Waqalat from "../../assets/images/Free Legal Documents, Advice, Contracts & Forms - Heading.png";
const Particles = React.lazy(() => import("./Particles"));
const MainPageSearch = React.lazy(() => import("./MainPageSearch"));

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "sans-serif",
    textAlign: "center",
    height: "100%",
    // backgroundColor: "#181a1b",
    backgroundColor: "#f2faff",
  },
  logoImage: {
    // border: "1px solid #fff",
    marginTop: 48,
    marginBottom: 0,
    zIndex: 1,
    width: 750,
    height: 200,
    [theme.breakpoints.down("sm")]: {
      height: 80,
      width: 300,
      marginBottom: 0,
    },
  },
  container: {
    // background: "#222",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
  },
  particlesContainer: {
    position: "absolute",
  },
  tcBar: {
    display: "flex",
    backgroundColor: "#fff",
    color: "#000",
    justifyContent: "center",
    alignItems: "center",
  },
  tcButton: {
    display: "inline",
    textTransform: "none",
    padding: 0,
    marginRight: 12,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "#3f3f3f",
    },
    // borderRadius: 0,
  },
  tcOkBtn: {
    backgroundColor: "#006098",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#000080",
    },
  },
}));

// Cookie stuff
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
// function eraseCookie(name) {
//   document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
// }

// MAIN FUNCTIONAL COMPONENT
export default function Header({ documents }) {
  const classes = useStyles();

  const [showTcBar, setShowTcBar] = useState(getCookie("showTCBar") || false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleTcClose() {
    setShowTcBar(true);
    setCookie("showTCBar", true, 180);
  }

  return (
    <>
      <div className={classes.tcBar} style={{ display: showTcBar ? "none" : "flex" }}>
        <Typography style={{ marginLeft: "auto" }}>
          Disclaimer: By using this site you agree to our&nbsp;
          <Link component="button" onClick={handleDialogOpen} style={{ textTransform: "none" }}>
            <Typography>Terms and Conditions</Typography>
          </Link>
        </Typography>
        <IconButton className={classes.tcButton} onClick={handleTcClose}>
          <CancelRoundedIcon />
        </IconButton>
        {/* Popup Dialog on click of t&c */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="dialog"
          PaperProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
        >
          <DialogTitle id="dialog-title">Terms and Conditions</DialogTitle>
          <DialogContent>
            <DialogContentText>
              As per the rules of the Bar Council of India, we are not permitted to solicit work and
              advertise. By visiting the website, the user acknowledges that the information
              provided on this website is solely available for informational purposes only sought to
              be voluntarily gained by him/her and is neither soliciting nor advertisement. Further,
              the information provided on this website is accessed by the user's own volition, and
              any transmission, receipt or use of this information available on this website does
              not create any liability or any relationship with us.
              <br />
              <br /> You agree not to use any Waqalat Material and Waqalat.in Services for any
              purpose which is against any law in your jurisdiction or for any purpose which would
              not give full effect to the Terms even if that purpose is otherwise allowed under the
              Terms.
              <br />
              <br /> Waqalat.in is not a law firm and does not provide Legal Advice (as defined
              below). Your use of Waqalat.in Material or any Waqalat.in Services does not create a
              solicitor-client relationship between you and Waqalat.in.
              <br />
              <br /> You agree that all decisions you make on legal matters are your full
              responsibility and you agree to retain legal counsel licensed to practice in your
              jurisdiction regarding any legal issue of enough importance to reasonably require it.
              <br />
              <br /> You agree that Waqalat.in does not provide Legal Advice. If you receive any
              communication from Waqalat.in, its agents, its employees or any other associated
              entity, which is Legal Advice, you agree that it is NOT a communication authorised by
              Waqalat.in and you agree to immediately delete and disregard it.
              <br />
              <br /> Except as expressly provided in these Terms, you agree to accept full
              responsibility for determining the value of and for any use you make of Waqalat.in
              Material, and for obtaining any needed assistance from a properly licensed attorney to
              assess the value of and appropriate uses for any Waqalat.in Material.
              <br />
              <br /> To the maximum extent allowable under applicable law, except as explicitly
              identified in these terms, you agree not to publish, re-publish, lend, licence, give
              away, look at the software source code, modify the software source code, post to an
              Internet web site, or use in an automated system any Waqalat.in Material nor will you
              utilise Waqalat.in Material in any way for the creation of an automated system or
              website, nor will you allow or assist a third party to do so.
              <br />
              <br /> You accept full responsibility for determining whether Waqalat.in Material are
              suitable for any particular purpose and for protecting yourself against any possible
              consequential damages.
              <br />
              <br /> Subject to exceptions specified herein, if you and Waqalat.in are unable to
              resolve any dispute by informal negotiations, then any resolution of this dispute will
              be conducted exclusively by binding arbitration. A request for appointment of an
              arbitrator must be made in writing. Upon receipt of the written request, Waqalat.in
              will have 90 days to choose and appoint an independent and impartial arbitrator. The
              arbitration will be held in the City of New Delhi, India.
              <br />
              <br /> The cost of the binding arbitration proceedings and any proceeding in court to
              confirm or to vacate any arbitration award, including, without limitation, reasonable
              attorneys' fees and costs, will be borne by the unsuccessful party and will be
              determined and awarded by the arbitrator. Exceptions to the use of binding arbitration
              are as follows: Waqalat.in may bring forth a lawsuit, without using binding
              arbitration, should the lawsuit involve intellectual property infringement or
              injunctive relief. Also, either party may use small claims court.
              <br />
              <br />
              Waqalat.in only saves such personal information that is necessary for you to access
              and use our services mainly live chat feature. This personal information includes, but
              is not limited to, first and last name, email address, phone number and document
              creation data. Waqalat.in will store such personal information until you formally
              request its deletion, or Waqalat.in otherwise deletes that personal information in
              accordance with its decisions or internal policies.
              <br />
              <br />
              Waqalat.in may, in its sole discretion, change these Terms at any time. Other terms
              and conditions are only valid when signed in writing by an authorised Waqalat.in
              officer.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose} className={classes.tcOkBtn}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.root}>
        <div className={classes.particlesContainer}>
          <Suspense fallback={<div>...</div>}>
            <Particles />
          </Suspense>
        </div>
        <div className={classes.container}>
          <img
            src={Waqalat}
            alt="Free Legal Documents, Advice, Contracts & Forms - Waqalat Main Logo"
            className={classes.logoImage}
            height="200"
          />
        </div>
        <div style={{ marginTop: 36 }}>
          <Suspense fallback={<div>...</div>}>
            <MainPageSearch documents={documents} />
          </Suspense>
        </div>
      </div>
    </>
  );
}
